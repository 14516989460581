<template>
  <div v-loading.fullscreen.lock="tableLoading1" class="purchaseRequestDetail p-lr-20 p-tb-20">
    <div class="header_div flex j-between ">
      <div class="left flex a-center j-center weight f12">
        申 请 信 息
      </div>
      <div class="mid">
        <div class="item min-border-bottom flex j-between a-center p-lr-10 f12">
          <div class="eachitem-eachword el-1"><span class="width_ hui">来源订单：</span><span class="no-weight">{{ from.OrderID }}</span></div>
          <div class="eachitem-eachword el-1"><span class="width_ hui">订单类型：</span><span class="no-weight">{{ from.OrderIDSourceShow }}</span></div>
          <div class="eachitem-eachword el-1"><span class="width_ hui">订单创建人：</span><span class="no-weight">{{ from.SourceOrderCreater }}</span></div>
          <div class="eachitem-eachword el-1"><span class="width_ hui">通知处理人：</span><span class="no-weight">{{ from.NoticeUserNickName }}</span></div>
        </div>
        <div class="item min-border-bottom flex j-between a-center p-lr-10 f12">
          <div class="eachitem-eachword el-1"><span class="width_ hui">采购申请：</span><span class="no-weight">{{ from.PRID }}</span></div>
          <div class="eachitem-eachword el-1"><span class="width_ hui">销售单号：</span><span class="no-weight">{{ from.StrOrderSapIDHead }}</span></div>
          <div class="eachitem-eachword el-1"><span class="width_ hui">订单客户：</span><span class="no-weight">{{ from.OrderCustomer }}</span></div>
          <div class="eachitem-eachword el-1"><span class="width_ hui">采购申请人：</span><span class="no-weight">{{ from.NickName }}</span></div>
        </div>
        <div class="item min-border-bottom flex j-between a-center p-lr-10 f12">
          <div class="eachitem-eachword el-1">
            <span class="width_ hui">效期要求：</span>
            {{ from.ExpiredRequireShow }}
            <!-- <el-select v-model="from.ExpiredDate" placeholder="请选择" size="mini">
              <el-option
                v-for="item in DateList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select> -->
          </div>
          <div class="eachitem-eachword el-1 ">
            <span class="width_ hui">入库仓位：</span><span class="no-weight"> {{ from.StockCode }}</span>
            <!-- <el-select v-model="from.ExpiredDate" placeholder="请选择" size="mini">
              <el-option
                v-for="item in DateList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select> -->
          </div>
          <div class="eachitem-eachword el-1"><span class="width_ hui">期望到货日期：</span><span class="no-weight">{{ from.ArrivalDate }}</span></div>
          <div class="eachitem-eachword el-1 ">
            <span class="hui" style="width:100px">是否直送客户：</span>
            {{ from.IsDirectSending ? '是' : '否' }}
            <!-- <el-checkbox-group v-model="checkList">
              <el-checkbox label="是" />
              <el-checkbox label="否" />
            </el-checkbox-group> -->
          </div>
        </div>
        <div class="item min-border-bottom flex j-between a-center p-lr-10  f12">
          <div class="el-1"><span class="width_ hui">收货地址：</span><span class="no-weight">{{ from.FullAddress }}</span></div>
          <div class=" el-1" style="width:25%"><span class="width_ hui">用户上门自提：</span><span class="no-weight">{{ from.IsTakeSelf ? '是' : '否' }}</span></div>
        </div>
        <div class="item min-border-bottom flex j-between a-center p-lr-10  f12">
          <div class="eachitem-eachword el-1" style="flex:1"><span class="width_ hui">寄票地址：</span><span class="no-weight">{{ from.CollectTicketSendAddress }}</span></div>
          <div class="flex a-center j-end">
            <span class="hui">订单备注：</span>
            <span class="no-weight  el-1" style="width:200px">{{ from.Remark }}</span>
            <div class="el-1"><span class="width_ hui">申请时间：</span><span class="no-weight">{{ from.WriteTime }}</span></div>
          </div>
        </div>
      </div>
      <div class="right" />
    </div>
    <div class="flex searchView j-between  m-tb-20">
      <div>
        <el-popover
          v-model="isExport"
          placement="bottom"
          trigger="manual"
          :width="80"
          popper-class="test_pop_view"
        >
          <div class="flex column xiala">
            <div @click="ExportOrderPurchaseInfoExcel(0)">全部导出</div>
            <div @click="ExportOrderPurchaseInfoExcel(1)">按已选导出</div>
          </div>
          <div slot="reference" class="ModelBttton1-white-30" @click="() => isExport = !isExport"><i class="el-icon-upload2" /> 导出</div>
        </el-popover>
      </div>
      <div v-if="from.Status !== 1 && from.Status !== 3 && from.Status !== 4 " class="flex">
        <div class="ModelBttton1-white-30" @click="hideContent">
          {{ isHide ? '隐藏已完成物料' : '显示全部物料' }}
        </div>
        <div class="flex column">
          <div class="ModelBttton1-white-30 m-left-5" @click="exportFile(from.OrderID ? 0 : 1)"> {{ from.OrderID ? '批量退回' : '批量删除' }}</div>
        </div>
        <div class="ModelBttton1-blue-30 m-left-5" @click="createOrder">
          生成采购单
        </div>
      </div>
    </div>
    <div>
      <el-table
        :data="tableData"
        style="width: 100%;"
        row-key="IDX"
        border
        @select-all="selectAll"
        @select="select"
      >
        <el-table-column fixed="left" type="selection" width="39" />
        <el-table-column prop="InvCode" label="物料编号" show-overflow-tooltip width="100" />
        <el-table-column prop="ManuPartNum" label="原厂编号" show-overflow-tooltip width="100" />
        <el-table-column prop="InvName" label="物料名称" show-overflow-tooltip width="200" />
        <el-table-column prop="BrandName" label="品牌" show-overflow-tooltip width="200" />
        <el-table-column prop="Specification" label="规格" show-overflow-tooltip width="200" />
        <el-table-column prop="StockUnitShow" label="单位" show-overflow-tooltip />
        <el-table-column prop="WarehouseStockNum" label="库存数量" show-overflow-tooltip />
        <el-table-column prop="Num" label="申请总数" show-overflow-tooltip />
        <el-table-column prop="ReturnNum" :label="from.OrderID ? '退回数量' :'删除数量'" show-overflow-tooltip />
        <el-table-column prop="ValidNum" label="未处理数量" show-overflow-tooltip width="100" />
        <el-table-column prop="LotNo" label="客户要求批号" show-overflow-tooltip width="100" />
        <el-table-column prop="POIDList" label="供应商订单号" show-overflow-tooltip width="100">
          <template slot-scope="scope">
            <el-link v-for="(item,index) in scope.row.POIDList" :key="index" :underline="false" type="primary" @click="() => $router.push({name:'orderDetails1', params:{ Ishandler:'-1', orderid: item, Purchase: true} })">{{ item }}</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="POCGIDList" label="采购单号" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-for="(item,index) in scope.row.POCGIDList" :key="index">{{ item }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="StatusShow" label="处理状态" show-overflow-tooltip />
        <el-table-column prop="set" label="操作" width="45" fixed="right">
          <template slot-scope="scope">
            <el-popover placement="left" trigger="hover" :width="130" popper-class="test_pop_view">
              <div class="DropDownList">
                <!-- <div class="">
                  <i class="el-icon-view p-right-10" />
                  <span>
                    <el-link type="primary" :underline="false">退回采购单</el-link>
                  </span>
                </div> -->
                <div class="OperationButton">
                  <el-link :underline="false" @click="viewList(scope.row)"><i class="el-icon-download p-right-10" /> 查看操作记录</el-link>
                </div>
              </div>
              <i slot="reference" class="el-icon-more" />
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 生成采购单 start -->
    <el-dialog v-loading="isWUli" :close-on-click-modal="false" class="DepartmentBox" width="80%" :visible.sync="visible" custom-class="dialog_view1" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">选择采购供应商</span>
        <i class="el-icon-close Close hui" @click="() => visible = false" />
      </div>
      <div class="header_div flex j-between m-top-20">
        <div class="left flex a-center j-center weight f12">
          申 请 信 息
        </div>
        <div class="mid">
          <div class="item min-border-bottom flex j-between a-center p-lr-10  f12">
            <div class="eachitem-eachword weight"><span class="width_">来源订单：</span><span class="p-left-10 no-weight">{{ from.OrderID }}</span></div>
            <div class="eachitem-eachword weight"><span class="width_">订单类型：</span><span class="p-left-10 no-weight">{{ from.OrderIDSourceShow }}</span></div>
            <div class="eachitem-eachword weight"><span class="width_">订单创建人：</span><span class="p-left-10 no-weight">{{ from.SourceOrderCreater }}</span></div>
            <div class="eachitem-eachword weight"><span class="width_">通知处理人：</span><span class="p-left-10 no-weight">{{ from.NoticeUserNickName }}</span></div>
          </div>
          <div class="item min-border-bottom flex j-between a-center p-lr-10   f12">
            <div class="eachitem-eachword weight"><span class="width_">采购申请：</span><span class="p-left-10 no-weight">{{ from.PRID }}</span></div>
            <div class="eachitem-eachword weight"><span class="width_">销售单号：</span><span class="p-left-10 no-weight">{{ from.StrOrderSapIDHead }}</span></div>
            <div class="eachitem-eachword weight"><span class="width_">订单客户：</span><span class="p-left-10 no-weight">{{ from.OrderCustomer }}</span></div>
            <div class="eachitem-eachword weight"><span class="width_">采购申请人：</span><span class="p-left-10 no-weight">{{ from.NickName }}</span></div>
          </div>
          <div class="item min-border-bottom flex j-between a-center p-lr-10  f12">
            <div class="eachitem-eachword weight flex a-center">
              <span style="width:70px">效期要求：</span>
              <el-select v-model="from.ExpiredRequire" class="SelectBoxModel1" placeholder="请选择" size="mini">
                <el-option
                  v-for="item in DateList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </div>
            <div class="eachitem-eachword weight flex a-center">
              <span style="width:70px">入库仓位：</span>
              <el-select v-model="from.StockCode" class="SelectBoxModel1" placeholder="请选择" size="mini">
                <el-option
                  v-for="item in StockList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </div>
            <div class="eachitem-eachword weight"><span class="width_">期望到货日期：</span><span class="p-left-10 no-weight">{{ from.ArrivalDate }}</span></div>
            <div class="eachitem-eachword weight  flex a-center">
              <span>是否直送客户：</span>
              <el-radio-group v-model="from.IsDirectSending" @change="isSendClient">
                <el-radio v-if="from.OrderCustomer" :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </div>
          </div>
          <!-- <div class="item min-border-bottom flex j-between a-center p-lr-10  f12">
            <div class="eachitem-eachword flex  weight el-1">
              <span class="">收货地址：</span>
              <span class="p-left-10 no-weight">{{ from.SendAddress }}</span>
              <span style="color:#409EFF" class="cursor p-left-10" @click="selectAddress(10)">编辑</span>
            </div>
            <div class="eachitem-eachword weight "><span class="width_">用户上门自提：</span><span class="p-left-10 no-weight">{{ from.IsTakeSelf ? '是':'否' }}</span></div>
          </div> -->
          <div class="item min-border-bottom flex j-between a-center p-lr-10 f12">
            <div class="weight flex" style="width:65%;flex:1">
              <span class="">收货地址：</span>
              <span v-if="from.FullAddress" class="p-left-10 no-weight  el-1" style="flex:1">{{ from.FullAddress }}</span>
              <span style="color:#409EFF;" class="cursor p-lr-10 " @click="selectAddress(10)">编辑</span>
            </div>
            <div class="weight  el-1" style="width:25%"><span class="width_">用户上门自提：</span><span class="p-left-10 no-weight">{{ from.IsTakeSelf ? '是' : '否' }}</span></div>
          </div>

          <div class="item min-border-bottom flex j-between a-center p-lr-10  el-1 f12">
            <div class="weight flex" style="width:50%;flex:1">
              <span class="">寄票地址：</span>
              <span v-if="from.CollectTicketSendAddress" class="p-left-10 no-weight   el-1" style="flex:1">{{ from.CollectTicketSendAddress }}</span>
              <span style="color:#409EFF;" class="cursor p-lr-10" @click="selectAddress(20)">编辑</span>
            </div>
            <div class="weight flex a-center j-between  j-end" style="width:45%">
              <div class="weight flex j-start" style="width:65%;flex:1">
                <span class="">订单备注：</span>
                <span v-if="from.Remark" class="p-left-10 no-weight  el-1" style="flex:1">{{ from.Remark }}</span>
                <span style="color:#409EFF" class="cursor p-lr-10" @click="() => showRemake = true">编辑</span>
              </div>
              <div class="weight el-1">
                <span class="">申请时间：</span>
                <span class="p-left-10 no-weight">{{ from.WriteTime }}</span>
              </div>
            </div>
            <!-- <div class="weight flex j-between a-center">
              <div class="weight flex a-center">
                <span class="">订单备注：</span>
                <span class="p-left-10 no-weight  el-1" style="width:60px">{{ from.Remark }}</span>
                <div style="color:#409EFF" class="cursor p-left-10" @click="() => showRemake = true">编辑</div>
              </div>
              <div class="weight text_end"><span class="width_">申请时间：</span><span class="p-left-10 no-weight">{{ from.WriteTime }}</span></div>
            </div> -->

          </div>
        </div>
        <div class="right" />
      </div>
      <div class="table_ m-top-20">
        <div class="flex a-center m-bottom-20">
          <div class="desc_view" />
          <div class="p-lr-5">背景色表示无有效采购合约</div>
          <el-link type="primary" :underline="false" @click="viewCG">点击查看采购合约</el-link>
        </div>
        <el-table
          ref="singleTable"
          :data="tableDataProducts"
          style="width: 100%"
          border
          :row-class-name="tableRowClassName"
          :header-cell-style="headerCStyle"
          @select-all="selectAllP"
          @select="selectP"
        >
          <el-table-column type="selection" width="40" />
          <el-table-column label="物料编号" prop="InvCode" show-overflow-tooltip width="100" />
          <el-table-column label="原厂编号" prop="ManuPartNum" show-overflow-tooltip width="80" />
          <el-table-column label="物料名称" prop="InvName" show-overflow-tooltip width="200" />
          <el-table-column label="规格" prop="Specification" show-overflow-tooltip width="200" />
          <el-table-column label="单位" prop="StockUnitShow" show-overflow-tooltip width="60" />

          <el-table-column label="申请数量" prop="Num" show-overflow-tooltip width="80" />
          <el-table-column prop="ValidNum" label="可处理数量" show-overflow-tooltip width="120" />
          <el-table-column label="采购单数量" prop="PurchaseOrderNum" width="90px">
            <template slot-scope="scope">
              <!-- <el-input v-model.number="scope.row.PurchaseOrderNum" size="mini" /> -->
              <el-input-number v-model="scope.row.PurchaseOrderNum" :min="1" size="mini" :controls="false" />
            </template>
          </el-table-column>
          <el-table-column label="选择供应商" prop="date" width="240" align="center">
            <template slot="header" slot-scope="scope">
              <div class="flex j-between a-center">
                <p>指定供应商</p>
                <!-- <el-input
                  v-model="search"
                  class="InputModel_24"
                  style="width:100px "
                  placeholder="输入关键字"
                /> -->
                <el-select v-model="search" clearable filterable style="width:115px" size="mini" placeholder="输入关键字">
                  <el-option
                    v-for="(item ,index) in optionsDataLast"
                    :key="index"
                    :label="item"
                    :value="item"
                  />
                </el-select>
                <el-button class="FileUpload_24 m-left-5" @click="searchGong(scope)">确认</el-button>
              </div>
            </template>
            <template slot-scope="scope">
              <div class="flex">
                <!-- <el-input size="mini" />  @change="ListContractInfoChange(scope.$index,scope.row)" -->
                <el-select v-model="scope.row.TaxRate" clearable class="SelectBoxModel2" placeholder="请选择" size="mini">
                  <el-option
                    v-for="(item,index) in scope.row.ListContractInfo"
                    :key="item.PurchaseContactID"
                    :label="item.label"
                    :disabled="item.IsBeOverdue === 1 ? true : false"
                    :value="item.SellCompanyID + ','+ item.PurchaseContactID + ',' + item.PurchaseContactDetailsID"
                    @click.native="ListContractInfoChange(scope.$index,index,item)"
                  />
                </el-select>
              </div>

            </template>
          </el-table-column>
          <el-table-column label="添加备注" prop="Remark">
            <template slot-scope="scope">
              <el-input v-model="scope.row.Remark" size="mini" />
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="footer_ flex j-end a-center m-top-20 min-border-top p-tb-20">
        <p>含税总价：￥</p>
        <p style="color:#409EFF" class="weight">{{ totals.toFixed(2) }}</p>元
        <div class="p-right-20" />
        <el-button type="primary" @click.native="submitContent1">生成采购单</el-button>
        <el-button @click="() => visible = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 生成采购单 end -->
    <!-- 修改备注 start -->
    <el-dialog :close-on-click-modal="false" class="DepartmentBox" width="600px" :visible.sync="showRemake" custom-class="dialog_view1" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">修改备注</span>
        <i class="el-dialog__close el-icon el-icon-close Close" @click="() => showRemake = false" />
      </div>
      <el-input
        v-model="isRemake"
        class="p-tb-20"
        type="textarea"
        :autosize="{ minRows: 4, maxRows: 8}"
        placeholder="请输入内容"
      />
      <div class="caozuo p-bottom-20">
        <el-button @click="() => showRemake = false">取消</el-button>
        <el-button type="primary" @click="sueRemake">确定</el-button>
      </div>
    </el-dialog>
    <!-- 修改备注 end -->
    <!-- 选择订单地址 start -->
    <el-dialog :close-on-click-modal="false" class="DepartmentBox" width="70%" :visible.sync="showAddress" custom-class="dialog_view1" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">{{ ruleForm1.AddrType === 10 ? '选择订单收货地址' :'选择订单寄票地址' }}</span>
        <i class="el-dialog__close el-icon el-icon-close Close" @click="() => showAddress = false" />
      </div>
      <!-- 地址 start-->
      <div v-loading="isAddressLoading" class="address_view_set flex column">
        <div class="p-tb-10 t-right">
          <el-button class="ModelBttton1-white-24" @click="() => showSetAddress = true">新建地址</el-button>
        </div>
        <div v-if="addressList.length" class="m-bottom-20">
          <div v-for="(item,index) in addressList" :key="index" style="height:auto" class="item flex a-center p-tb-10 p-lr-10">
            <div style="flex:1" class="el-2">
              {{ item.Province + item.City + item.Area + `${item.Street ? item.Street :''}` + item.Address + `${item.ContactName ? item.ContactName : ''}` + `${item.ContactTel ? item.ContactTel : ''}` }}
            </div>
            <div style="min-width:298px" class="flex">
              <el-button class="ModelBttton1-white-24" @click="edit(item)">编辑</el-button>
              <el-button class="ModelBttton1-white-24" @click="DeleteUserAddressInfo(item.IDX)">删除</el-button>
              <el-button v-if="!item.DefaultFlag" class="ModelBttton1-white-24" @click="SetDefalutAddress(item.IDX)">设为默认地址</el-button>
              <el-button v-if="item.SellStatus == 0" class="ModelBttton1-white-24" @click="slectAddressInfo(item)">选择</el-button>
              <el-button v-else class="ModelBttton1-white-24">{{ item.SellStatusString }}</el-button>
            </div>
          </div>
        </div>
        <div v-else class="p-tb-30 center">暂无数据</div>
      </div>
      <!-- 地址 end-->
    </el-dialog>
    <!-- 选择订单地址 end -->
    <!-- 新建地址 start -->
    <el-dialog :close-on-click-modal="false" class="DepartmentBox" width="430px" :visible.sync="showSetAddress" custom-class="dialog_view1" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">新建地址</span>
        <i class="el-dialog__close el-icon el-icon-close Close" @click="() => showSetAddress = false" />
      </div>
      <el-form ref="ruleForm1" :model="ruleForm1" :rules="rules1" label-width="100px" class="demo-ruleForm p-top-10">
        <el-form-item label="上门自提" prop="name">
          <el-checkbox v-model="ruleForm1.TakeSelf">备选项</el-checkbox>
        </el-form-item>
        <el-form-item label="省" prop="Province">
          <el-cascader
            v-model="ruleForm1.Province"
            size="mini"
            :options="Province"
            style="width:290px"
            @change="handleChange"
          />
          <!-- <el-input v-model="ruleForm1.Province" size="mini" /> -->
        </el-form-item>
        <el-form-item label="市" prop="City">
          <el-cascader
            v-model="ruleForm1.City"
            size="mini"
            :options="City"
            style="width:290px"
            @change="handleChange1"
          />
          <!-- <el-input v-model="ruleForm1.City" size="mini" /> -->
        </el-form-item>
        <el-form-item label="区" prop="Area">
          <el-cascader
            v-model="ruleForm1.Area"
            size="mini"
            :options="Area"
            style="width:290px"
          />
          <!-- <el-input v-model="ruleForm1.Area" size="mini" /> -->
        </el-form-item>
        <el-form-item label="街道" prop="Street">
          <el-input v-model="ruleForm1.Street" size="mini" />
        </el-form-item>
        <el-form-item label="地址" prop="Address">
          <el-input v-model="ruleForm1.Address" size="mini" />
        </el-form-item>
        <el-form-item label="收货部门" prop="DeptName">
          <el-input v-model="ruleForm1.DeptName" size="mini" />
        </el-form-item>
        <el-form-item label="联系人" prop="ContactName">
          <el-input v-model="ruleForm1.ContactName" size="mini" />
        </el-form-item>
        <el-form-item label="联系电话" prop="ContactTel">
          <el-input v-model="ruleForm1.ContactTel" size="mini" />
        </el-form-item>
        <el-form-item label="上传附件" prop="Filepath">
          <input id="file" type="file" name="file" multiple="multiple" @change="handleFileChange">
        </el-form-item>
        <el-form-item class="caozuo p-tb-20 t_line">
          <el-button @click="() => showSetAddress = false">取消</el-button>
          <el-button type="primary" @click="submitAddress('ruleForm1')">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 新建地址 end-->
    <!-- 采购申请退回、删除操作 start-->
    <el-dialog :close-on-click-modal="false" width="80%" :visible.sync="showSet" custom-class="dialog_view1" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <p class="p-left-20 weight">采购申请{{ isDelOrBack === 0 ? '退回':'删除' }}操作</p>
        <p class="p-right-10 weight f16 cursor" style="color:#000" @click="() => showSet = false">x</p>
      </div>
      <div style="height:30px" />
      <el-table
        :data="selects"
        style="width: 100%;"
        border
        :header-cell-style="headerCStyle"
      >
        <el-table-column prop="InvCode" label="物料编号" show-overflow-tooltip width="100" />
        <el-table-column prop="ManuPartNum" label="原厂编号" show-overflow-tooltip width="100" />
        <el-table-column prop="InvName" label="物料名称" show-overflow-tooltip width="200" />
        <el-table-column prop="BrandName" label="品牌" show-overflow-tooltip width="200" />
        <el-table-column prop="Specification" label="规格" show-overflow-tooltip width="200" />
        <el-table-column prop="StockUnitShow" label="单位" show-overflow-tooltip width="100" />
        <el-table-column prop="LotNo" label="客户要求批号" show-overflow-tooltip width="130" />
        <el-table-column prop="Num" label="申请数量" show-overflow-tooltip width="100" />
        <el-table-column prop="ValidNum" label="可处理数量" show-overflow-tooltip width="120" />
        <el-table-column prop="OperationNum" width="100" :label="`${isDelOrBack === 0 ? '退回':'删除'}数量`">
          <template slot-scope="scope">
            <!-- <el-input v-model.number="scope.row.OperationNum" type="number" min="1" size="mini" /> -->
            <el-input-number v-model="scope.row.OperationNum" style="width:70px" :min="1" :max="scope.row.ValidNum" size="mini" :controls="false" />
          </template>
        </el-table-column>
      </el-table>
      <div class="flex m-tb-20">
        <p style="width:80px">{{ isDelOrBack === 0 ? '退回':'删除' }}原因</p>
        <el-input v-model="OperrationReason" />
      </div>
      <div class="footers min-border-top flex a-center j-center">
        <el-button size="mini" round @click="() => showSet = false">取消</el-button>
        <el-button size="mini" type="primary" :loading="btnLoading" round @click="backOrDel">确定{{ isDelOrBack === 0 ? '退回':'删除' }}</el-button>
      </div>
    </el-dialog>
    <!-- 采购申请退回、删除操作 end-->
    <!-- 操作记录 start -->
    <el-dialog :close-on-click-modal="false" class="DepartmentBox" width="80%" :visible.sync="isSetList" custom-class="dialog_view1" :show-close="false">
      <div class="PageHeader1 flex j-between">
        <span class="Name">采购申请处理记录</span>
        <i class="el-icon-close Close" @click="() => isSetList = false" />
      </div>
      <el-table
        :data="setList"
        style="width: 100%;"
        border
        :header-cell-style="headerCStyle"
      >
        <el-table-column
          type="index"
          width="50"
          label="序号"
        />
        <el-table-column prop="InvCode" label="物料编号" show-overflow-tooltip />
        <el-table-column prop="ManuPartNum" label="原厂编号" show-overflow-tooltip />
        <el-table-column prop="InvName" label="物料名称" show-overflow-tooltip />
        <el-table-column prop="BrandName" label="品牌" show-overflow-tooltip />
        <el-table-column prop="Specification" label="规格" show-overflow-tooltip />
        <el-table-column prop="StockUnitShow" label="单位" show-overflow-tooltip />
        <el-table-column prop="LotNo" label="客户要求批号" show-overflow-tooltip width="100" />
        <el-table-column prop="Num" label="申请数量" show-overflow-tooltip />
        <el-table-column prop="OperationNum" label="退回数量" show-overflow-tooltip />
        <el-table-column prop="OperationNickName" label="操作人" show-overflow-tooltip />
        <el-table-column prop="OperationTime" label="操作时间" show-overflow-tooltip />
        <el-table-column prop="OperrationReason" label="备注" show-overflow-tooltip />
      </el-table>
      <div class="caozuo p-tb-20">
        <el-button @click="() => isSetList = false">关闭</el-button>
      </div>
    </el-dialog>
    <!-- 操作记录 end -->
    <!-- 证照预警 start -->
    <el-dialog :close-on-click-modal="false" class="DepartmentBox" width="800px" :visible.sync="showChecCertificate" custom-class="dialog_view" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">证照有效期告警</span>
        <i class="el-icon-close hui close" @click="() => showChecCertificate = false" />
      </div>
      <p class="hui_title">供应商证照到期告警</p>
      <el-table
        v-loading="loadingLicence"
        :data="licence"
        style="width: 100%;"
        border
        :header-cell-style="headerCStyle"
      >
        <el-table-column prop="CompanyName" label="公司名称" show-overflow-tooltip />
        <el-table-column prop="CertificateTypeName" label="证照类别" show-overflow-tooltip />
        <el-table-column prop="CertificateCode" label="证照编号" show-overflow-tooltip />
        <el-table-column prop="ValidTo" label="有效期至" show-overflow-tooltip />

      </el-table>
      <p class="hui_title">物料证照到期告警</p>
      <el-table
        v-loading="loadingLicence"
        :data="licence1"
        style="width: 100%;"
        border
        :header-cell-style="headerCStyle"
      >
        <el-table-column prop="InvCode" label="物料编号" show-overflow-tooltip />
        <el-table-column prop="ManuPartNum" label="原厂编号" show-overflow-tooltip />
        <el-table-column prop="InvName" label="物料名称" show-overflow-tooltip />
        <el-table-column prop="Specification" label="规格" show-overflow-tooltip />
        <el-table-column prop="BrandName" label="品牌" show-overflow-tooltip />
        <el-table-column prop="CertificateTypeName" label="证照类别" show-overflow-tooltip />
        <el-table-column prop="CertificateName" label="证照编号" show-overflow-tooltip />
        <el-table-column prop="CertificateDeadLine" label="有效期至" show-overflow-tooltip />
      </el-table>
      <div class="PsText">以上供应商资质/物料注册证已过期或即将到期，请及时向供应商索取最新证照，是否继续生成采购单？</div>
      <div class="caozuo p-tb-20 line">
        <el-button @click="cancelCreate">否</el-button>
        <el-button type="primary" @click="submitContent">是</el-button>
      </div>
    </el-dialog>
    <!-- 证照预警 end -->
    <!-- 采购单申请创建成功 start -->
    <el-dialog :close-on-click-modal="false" class="DepartmentBox" width="600" :visible.sync="showSuccess" custom-class="dialog_view" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">采购申请创建成功</span>
        <i class="el-dialog__close el-icon el-icon-close Close" @click="() => showSuccess = false" />
      </div>
      <div class="flex j-center a-center f12">
        <p>你的采购订单已经创建成功，采购订单号</p>
        <p class="theme-font">{{ logNum }}</p>
      </div>
      <div class="caozuo p-tb-20 t_line">
        <el-button type="primary" @click="sure">确定</el-button>
      </div>
    </el-dialog>
    <!-- 采购单申请创建成功 end -->
  </div>
</template>
<script>
import Index from '@/minxin/purchasing-management/purchaseRequestDetail'
export default Index
</script>
<style >
  .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .el-table .success-row {
    background:linear-gradient(-45deg,rgba(0, 0, 0, .05) 0, rgba(0, 0, 0, .05) 25%, transparent 25%, transparent 50%,rgba(0, 0, 0, .05) 50%, rgba(0, 0, 0, .05) 75%, transparent 75%, transparent);
    background-size: 10px 10px;
  }
</style>
<style lang='scss' scoped>
@import "@/style/search.scss";
@import "@/style/purchase.scss";
</style>

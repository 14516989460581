/* eslint-disable no-unreachable */
import PageHeader from '@/components/PageHeader'
import Adsearch from '@/components/Adsearch'
import tableMixin from '@/minxin/table-minxin'
import { regionData, CodeToText, TextToCode } from 'element-china-area-data' // CodeToText
const Index = {
  name: 'purchaseRequestDetail',
  mixins: [tableMixin],
  components: {
    PageHeader,
    Adsearch
  },
  mounted() {
    // this.$refs.singleTable.setCurrentRow(1, true)
    // this.isHide = false
    console.log('params', this.$route.params)
    this.QueryPurchaseApplyByID()
    this.QueryExpiredDateList()
    this.QueryInStockList()
    regionData.map(item => {
      this.Province.push({
        label: item.label,
        value: item.value
      })
    })
  },
  data() {
    return {
      btnLoading: false,
      isAddressLoading: false,
      showSetAddress: false,
      addressList: [],
      visible1: false,
      from: {
        PurchaseOrderNum: 0,
        TaxRate: '', // 税率
        OrderID: '', // "订单来源",
        OrderIDSourceShow: '', // "订单类型",
        SourceOrderCreater: '', // "订单创建人",
        NoticeUserNickName: '', // "通知处理人",
        PRID: '', // "采购申请",
        StrOrderSapIDHead: '', // "销售单号",
        OrderCustomer: '', // "订单客户",
        BEDNRCompanyID: '', // "客户IDX",
        NickName: '', // "采购申请人",
        ExpiredRequireShow: '', // "效期要求",
        StockCode: '', // "入库仓位",
        IsDirectSending: '', // "是否直送",
        IsTakeSelf: '', // "用户上门自提",
        WriteTime: '', // "申请时间",
        Remark: '', // "备注",
        SendAddress: '', // "收货地址",
        CollectTicketSendAddress: '', // "寄票地址",
        ExpiredDate: '',
        ArrivalDate: '', // 期望到货日期
        LotNoRequire: true,
        isKe: ['是'],
        DivisionDeptID: '',
        SendID: '',
        CollectTicketSendID: '',
        Remarks: '',
        NoticeUser: '',
        BednrCompanyID: '',
        card: '',
        address: ''
      },
      ruleForm1: {
        IDX: '',
        TakeSelf: '', // 是否自提-0:否 1:是,
        Province: '',
        City: '',
        Area: '',
        Street: '',
        Address: '',
        DeptName: '',
        ContactName: '',
        ContactTel: '',
        Filepath: '',
        AddrType: 10 // 地址类型-10 收货地址 20 收票地址
      },
      rules1: {
        Province: [
          { required: true, message: '请输入省', trigger: 'blur' }
        ],
        City: [
          { required: true, message: '请输入市', trigger: 'blur' }
        ],
        Area: [
          { required: true, message: '请输入区', trigger: 'blur' }
        ],
        Address: [
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ]
      },
      BEDNRCompanyID: 0, // 暂存id
      showRemake: false,
      showAddress: false,
      DateList: [],
      checkList: [],
      StockList: [],
      visible: false,
      textarea2: '',
      tableDataProducts: [],
      Province: [],
      City: [],
      Area: [],
      search: '',
      selects: [],
      DetailIDList: [],
      selectsP: [],
      showSet: false,
      OperrationReason: '',
      isDelOrBack: 0,
      isRemake: '',
      isExport: false,
      isHide: false,
      isSetList: false,
      setList: [],
      tableLoading1: false,
      showChecCertificate: false,
      licence: [],
      licence1: [],
      showSuccess: false,
      loadingLicence: false,
      isWUli: false,
      logNum: '',
      optionsDataLast: [],
      totals: 0,
      optionsData: [] // 选择供应商数据
    }
  },
  watch: {
    optionsData(a) {
      const arr = []
      if (a.length) {
        console.log('供应商', a)
        a.forEach(item => {
          item.forEach(item1 => {
            arr.push(item1.label)
          })
        })
        this.optionsDataLast = arr
      }
    },
    isHide(a) {
      const temp = []
      Object.assign(temp, this.tableData)
      console.log(temp)
      if (a) {
        // 筛选
        // this.tableData = this.tableData.filter(item => item.Status !== 1)
        // console.log(this.tableData)
        this.tableLoading1 = true
        this.$api.QueryPurchaseApplyByID({
          idx: this.$route.params.IDX
        }).then(res => {
          this.tableData = res.Message.ListOrderPurchaseInfo
          this.tableLoading1 = false
        }).catch(() => {
          this.tableLoading1 = false
        })
        return
      }
      this.QueryPurchaseApplyByID()
    },
    selects(a) {
      this.DetailIDList = []
      if (a.length <= 0) return
      a.map(item => {
        this.DetailIDList.push(item.IDX)
      })
    }
  },
  // computed: {
  //   totals() {
  //     let num = 0
  //     this.tableDataProducts.map(item => {
  //       console.log('item', item)
  //       if (!item.Price_price) {
  //         item.Price_price = 0
  //       }
  //       num += item.Price_price * item.PurchaseOrderNum
  //       // if (item.ListContractInfo !== null && item.ListContractInfo.length > 0) {
  //       //   item.ListContractInfo.map(item2 => {
  //       //     console.log('item2', item2)
  //       //     num += item2.Price * item.PurchaseOrderNum
  //       //   })
  //       // }
  //     })
  //     return num
  //   }
  // },
  methods: {
    sure() {
      this.colseModel()
      this.$router.push({ name: 'purchase-order', params: { isCache: 6 }})
      const IndexAitem = this.$store.state.index.cachedViews.findIndex(item1 => item1 === 'purchaseRequestDetail')
      this.$store.state.index.cachedViews.splice(IndexAitem, 1)
      this.$store.dispatch('index/setCachedViews', this.$store.state.index.cachedViews)
    },
    viewCG() {
      this.colseModel()
      this.$router.push({ name: 'salesContract1', meta: { title: '采购合约', icon: 'el-icon-s-platform', contractType: 1 }})
    },
    colseModel() {
      this.showAddress = false
      this.showRemake = false
      this.visible = false
      this.showSetAddress = false
      this.showSet = false
      this.isSetList = false
      this.showChecCertificate = false
      this.showSuccess = false
    },
    // 监听生成采购单 是否直送客户
    isSendClient(e) {
      console.log(e)
      this.from.SendAddress = ''
      if (!e) {
        // 点击否
        return this.from.BEDNRCompanyID = 0
      }
      this.from.BEDNRCompanyID = this.BEDNRCompanyID
    },
    // 获取明细
    QueryPurchaseApplyByID() {
      this.tableLoading1 = true
      this.$api.QueryPurchaseApplyByID({
        idx: this.$route.params.IDX
      }).then(res => {
        console.log(res)
        if (res.RetCode !== '0') {
          this.tableLoading1 = false
          return this.$message.error('请求错误：' + res.RetMsg)
        }
        this.from = res.Message
        console.log('this.from测试', this.from)
        this.BEDNRCompanyID = this.from.BEDNRCompanyID
        if (!this.from.ExpiredRequire) this.from.ExpiredRequire = ''
        // this.tableData = res.Message.ListOrderPurchaseInfo
        console.log('this.from.Status', this.from.Status)
        this.tableData = res.Message.ListOrderPurchaseInfo
        if (this.from.Status !== 1) {
          this.tableData = this.tableData.filter(item => item.Status !== 1)
        }

        this.tableLoading1 = false
      }).catch(() => {
        this.tableLoading1 = false
      })
    },
    hideContent() {
      this.isHide = !this.isHide
    },
    // 选择供应商
    SelectContract() {
      this.$api.SelectContract({
        idx: this.$route.params.IDX,
        DetailIDList: this.DetailIDList.length > 0 ? this.DetailIDList : []
      }).then(res => {
        console.log(res)
        if (res.RetCode !== '0') {
          // this.tableLoading1 = false
          this.isWUli = false
          return this.$message.error('请求错误：' + res.RetMsg)
        }
        this.tableDataProducts = res.Message.ListOrderPurchaseInfo
        this.tableDataProducts.map((item, index) => {
          // ListContractInfo
          if (item.ListContractInfo.length) {
            this.optionsData.push(item.ListContractInfo)
            this.$set(item, 'TaxRate', item.ListContractInfo[0].SellCompanyID + ',' + item.ListContractInfo[0].PurchaseContactID + ',' + item.ListContractInfo[0].PurchaseContactDetailsID)
            this.$set(item, 'Price_price', item.ListContractInfo[0].Price)
          }
          // this.ListContractInfoChange(index, item)
          this.$nextTick(() => {
            this.$refs.singleTable.toggleRowSelection(item, true)
            this.selectAllP(this.tableDataProducts)
            this.$set(item, 'PurchaseOrderNum', item.ValidNum)
            if (!item.Price_price) { item.Price_price = 0 }
            this.totals += item.Price_price * item.ValidNum
          })
        })

        this.isWUli = false
      })
    },
    sueRemake() {
      this.from.Remark = this.isRemake
      this.showRemake = false
      this.isRemake = ''
    },
    selectAll(e) {
      console.log(e) // MaterialLotNo
      this.selects = e
    },
    select(row) {
      this.selects = row
    },
    selectAllP(e) {
      this.selectsP = e
    },
    selectP(row) {
      this.selectsP = row
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.ListContractInfo === null || row.ListContractInfo.length === 0) {
        return 'success-row'
      }
      // row.ListOrderPurchaseInfo.map(item => {
      //   if (item.ListContractInfo === null || item.ListContractInfo.length === 0) {
      //     return 'success-row'
      //   }
      // })
      return ''
    },
    // 查看操作记录
    viewList(row) {
      this.isSetList = true
      this.$api.QueryOrderPurchaseOperrationInfoList({
        PageIndex: 1,
        PageSize: 15,
        OrderPurchaseInfoID: row.IDX
      }).then(res => {
        console.log(res)
        this.setList = res.Message
      })
    },
    // 删除或退回
    backOrDel() {
      if (this.selects.length <= 0) return this.$message.warning('请选择你要操作的物料')
      console.log(this.selects)
      this.btnLoading = true

      const arr = this.selects.filter(item => item.ValidNum < item.OperationNum)
      if (arr.length > 0) return this.$message.warning('不可超出可处理数量')
      const obj = {
        PurchaseBasciID: this.$route.params.IDX, // 采购申请IDX,
        OperrationReason: this.OperrationReason, //,
        DetailList: []
      }
      this.selects.map(item => {
        obj.DetailList.push({
          OrderPurchaseInfoID: item.IDX,
          OperationNum: item.OperationNum
        })
      })
      console.log(obj)
      if (this.isDelOrBack === 0) {
        // 退回
        this.$api.BackPurchaseApplyDetail(obj).then(res => {
          this.showSet = false
          this.btnLoading = false
          console.log(res)
          if (res.RetCode === '0') {
            this.QueryPurchaseApplyByID()
            return this.$message.success('退回成功')
          }
          return this.$message.error('退回失败：' + res.RetMsg)
        })
      }
      if (this.isDelOrBack === 1) {
        // 删除
        this.$api.DeletePurchaseApplyDetail(obj).then(res => {
          this.btnLoading = false
          this.showSet = false
          if (res.RetCode === '0') {
            this.QueryPurchaseApplyByID()
            return this.$message.success('删除成功')
          }
          return this.$message.error('删除失败：' + res.RetMsg)
        })
      }
    },
    exportFile(type) {
      if (this.selects.length <= 0) return this.$message.warning('请选择你要操作的物料')
      this.visible1 = false
      this.isDelOrBack = type
      this.showSet = true
    },
    // 搜索供应商
    searchGong() {
      const arr = this.tableDataProducts.filter(item => item.ListContractInfo.length > 0)
      if (arr.length <= 0) return
      arr.forEach(item => {
        item.ListContractInfo.forEach(item1 => {
          if (item1.label.indexOf(this.search) >= 0) {
            this.$set(item, 'TaxRate', item1.SellCompanyID + ',' + item1.PurchaseContactID + ',' + item1.PurchaseContactDetailsID)
          }
        })
      })
    },
    // 导出
    ExportOrderPurchaseInfoExcel(type) {
      this.isExport = false
      const obj = {
        PurchaseBasciID: this.$route.params.IDX, //, 采购申请ID,
        ExportType: type, //, 0:全部 1:按已选,
        SelectIDList: [] //, [1,2,3] --选中的IDX集合
      }
      obj.SelectIDList = []
      if (type === 1) {
        if (this.selects.length <= 0) return this.$message.warning('请选择你要导出的物料')
        this.selects.map(item => {
          obj.SelectIDList.push(item.IDX)
        })
      }
      this.$api.ExportQueryPurchaseApplyByID(obj).then(res => {
        if (res.RetCode === '0' && res.Message) {
          return this.$minCommon.downloadFile(res.Message)
        }
        return this.$message.error('请求错误,' + res.RetMsg)
      })
    },
    cancelCreate() {
      this.showChecCertificate = false
      this.createOrder()
    },
    // 先告警弹窗
    submitContent1() {
      // this.visible = false
      // this.ChecCertificate()
      if (this.selectsP.length <= 0) {
        return this.$message.error('没有任何选中，无法生成采购单')
      }
      const obj = {
        MaterialRefCompanyID: '',
        SellCompanyID: ''
      }
      const arr = []
      const brr = []
      const flag2 = this.selectsP.filter(item => item.PurchaseOrderNum <= 0)
      if (flag2.length > 0) return this.$message.error('采购单数量不可为0')
      const flag1 = this.selectsP.filter(item => item.ValidNum < item.PurchaseOrderNum)
      if (flag1.length > 0) return this.$message.error('采购单数量超出可选范围')
      const flag = this.selectsP.filter(item => item.TaxRate === undefined)
      if (flag.length > 0) return this.$message.error('指定供应商不能为空')
      this.selectsP.map(item => {
        brr.push(item.MaterialRefCompanyID)
        arr.push(item.TaxRate.split(',')[0])
      })
      arr.map(item => {
        obj.SellCompanyID += `${item},`
      })
      brr.map(item => {
        obj.MaterialRefCompanyID += `${item},`
      })
      console.log('obj', obj)
      this.ChecCertificate(obj)
      if (this.licence.length > 0 && this.licence1.length > 0) {
        return this.showChecCertificate = true // 告警证照弹窗
      }
      return this.submitContent()
    },
    // 生成采购单
    submitContent() {
      console.log(this.from)
      const obj = {
        IDX: '', // "采购申请ID",
        ExpiredRequire: '', //  效期要求,
        StockCode: '', //  "入库仓位",
        IsDirectSending: '', //  是否直送,
        SendID: '', //  收货地址,
        CollectTicketSendID: '', //  收票地址,
        NoticeUser: '', // "通知处理人",
        Remark: '', // 备注,
        ArrivalDate: '', // 期望到货日期
        DetailList: []
      }
      for (const key in obj) {
        obj[key] = this.from[key]
        // console.log(key)
      }
      obj.DetailList = []
      if (this.selectsP.length > 0) {
        const flag = this.selectsP.filter(item => item.TaxRate === undefined)
        console.log(flag)
        if (flag.length > 0) return this.$message.error('必选字段为空')
        this.selectsP.map(item => {
          console.log(item)
          obj.DetailList.push({
            OrderPurchaseInfoID: item.IDX,
            SellCompanyID: item.TaxRate.split(',')[0],
            PurchaseContactID: item.TaxRate.split(',')[1],
            PurchaseContactDetailsID: item.TaxRate.split(',')[2],
            InvCode: item.InvCode,
            Num: item.PurchaseOrderNum, // 采购数量,
            Remarks: item.Remark // "备注"
          })
        })
      }
      console.log(obj)
      this.$api.CreatePurchaseOrder(obj).then(res => {
        this.visible = false
        console.log(res)
        if (res.RetCode === '0') {
          this.QueryPurchaseApplyByID()
          this.showChecCertificate = false // 告警证照弹窗
          this.showSuccess = true
          this.logNum = res.Message
          return this.$message.success('提交成功')
        }
        return this.$message.error('提交失败：' + res.RetMsg)
      })
    },
    // 获取证照告警接口
    ChecCertificate(obj) {
      this.loadingLicence = true
      this.$api.ChecCertificate({ MaterialRefCompanyID: obj.MaterialRefCompanyID, SellCompanyID: obj.SellCompanyID }).then(res => {
        console.log(res)
        if (res.RetCode === '0') {
          this.licence = res.Message.CompanyLicenses
          this.licence1 = res.Message.MaterialLicenses
        }
        this.loadingLicence = false
      })
    },
    // 选中复制
    ListContractInfoChange(index1, index, row) {
      this.$set(this.tableDataProducts[index1], 'Price_price', row.Price)
      this.totals = 0
      this.tableDataProducts.map(item => {
        if (!item.Price_price) { item.Price_price = 0 }
        this.totals += item.Price_price * item.PurchaseOrderNum
      })
    },
    // 复制到页面上实现获取数据逻辑
    async getTableDataAsync(page, pageSize) {
      const response = { Message: [] }
      return response
    },
    createOrder() {
      this.visible = true
      this.isWUli = true
      this.optionsData = []
      // 获取列表
      this.SelectContract()
    },
    // 收货地址
    QueryDeliveryAddressList() {
      this.$api.QueryDeliveryAddressList({
        companyID: this.from.BEDNRCompanyID ? this.from.BEDNRCompanyID : 0
      }).then(res => {
        console.log(res)
        this.addressList = []
        this.addressList = res.Message
        this.isAddressLoading = false
      })
    },
    // 编辑地址
    edit(item) {
      for (const key in this.ruleForm1) {
        this.ruleForm1[key] = item[key]
      }
      console.log(this.ruleForm1)
      this.showSetAddress = true
      const province = this.ruleForm1.Province
      const cityq = this.ruleForm1.City
      const area = this.ruleForm1.Area
      this.ruleForm1.Province = TextToCode[province].code
      this.handleChange([this.ruleForm1.Province])
      console.log('cityq', cityq)
      console.log('province', province)
      this.ruleForm1.City = TextToCode[province][cityq].code
      this.Area = []
      const City2 = regionData.filter(item => item.value === this.ruleForm1.Province)
      const Area2 = City2[0].children.filter(item => item.value === this.ruleForm1.City)
      if (Area2[0].children) {
        Area2[0].children.map(item => {
          this.Area.push({
            label: item.label,
            value: item.value
          })
        })
      }
      this.ruleForm1.Area = TextToCode[province][cityq][area].code
      console.log(this.ruleForm1)
      this.showSetAddress = true
    },
    // 收票地址
    QueryCollectTicketAddressList() {
      this.$api.QueryCollectTicketAddressList({
        companyID: this.from.BEDNRCompanyID ? this.from.BEDNRCompanyID : 0
      }).then(res => {
        console.log(res)
        this.addressList = []
        this.addressList = res.Message
        this.isAddressLoading = false
      })
    },
    // 删除地址
    DeleteUserAddressInfo(IDX) {
      this.$api.DeleteUserAddressInfo({
        IDX
      }).then(res => {
        console.log(res)
        if (res.RetCode === '0') {
          if (this.ruleForm1.AddrType === 10) {
            this.QueryDeliveryAddressList()
          }
          if (this.ruleForm1.AddrType === 20) {
            this.QueryCollectTicketAddressList()
          }
          return this.$message.success('删除成功')
        }
        return this.$message.error('删除失败')
      })
    },
    // 设置默认地址
    SetDefalutAddress(IDX) {
      console.log(this.ruleForm1)
      this.$api.SetDefalutAddress({ IDX }).then(res => {
        if (res.RetCode === '0') {
          if (this.ruleForm1.AddrType === 10) {
            this.QueryDeliveryAddressList()
          }
          if (this.ruleForm1.AddrType === 20) {
            this.QueryCollectTicketAddressList()
          }
          return this.$message.success('设置成功')
        }
        return this.$message.error('设置失败')
      })
    },
    // 选择
    slectAddressInfo(item) {
      if (this.ruleForm1.AddrType === 10) {
        this.from.SendID = item.IDX
        this.from.SendAddress = `
        ${item.Province ? item.Province : ''}
        ${item.City ? item.City : ''}
        ${item.Area ? item.Area : ''}
        ${item.Street ? item.Street : ''}
        ${item.Address ? item.Address : ''}
        ${item.ContactName ? item.ContactName : ''}
        ${item.ContactTel ? item.ContactTel : ''}
        `
      }
      if (this.ruleForm1.AddrType === 20) {
        this.from.CollectTicketSendID = item.IDX
        this.from.CollectTicketSendAddress = `
        ${item.Province ? item.Province : ''}
        ${item.City ? item.City : ''}
        ${item.Area ? item.Area : ''}
        ${item.Street ? item.Street : ''}
        ${item.Address ? item.Address : ''}
        ${item.ContactName ? item.ContactName : ''}
        ${item.ContactTel ? item.ContactTel : ''}
        `
      }
      this.showAddress = false
    },
    // 选择地址
    selectAddress(type) {
      this.showAddress = true
      this.ruleForm1.AddrType = type
      if (type === 10) {
        this.QueryDeliveryAddressList()
      }
      if (type === 20) {
        this.QueryCollectTicketAddressList()
      }
    },
    // 期效要求查询
    QueryExpiredDateList() {
      this.$api.QueryExpiredDateList().then(res => {
        console.log('效期', res.Message)
        this.DateList = res.Message
        this.DateList.map(item => {
          item.value = item.value * 1
        })
        console.log(this.DateList)
      })
    },
    // 入库仓库 QueryInStockList
    QueryInStockList() {
      this.$api.QueryInStockList().then(res => {
        this.StockList = res.Message
      })
    },
    handleChange(value) {
      console.log(value[0])
      this.City = []
      const City = []
      Object.assign(City, regionData.filter(item => item.value === value[0]))
      City[0].children.map(item => {
        this.City.push({
          label: item.label,
          value: item.value
        })
      })
      console.log(this.City)
    },
    handleChange1(value) {
      this.Area = []
      const City = regionData.filter(item => item.value === this.ruleForm1.Province[0])
      console.log(City)
      const Area = City[0].children.filter(item => item.value === value[0])
      console.log(Area)
      if (Area[0].children) {
        Area[0].children.map(item => {
          this.Area.push({
            label: item.label,
            value: item.value
          })
        })
      }
    },
    handleFileChange(e) {
      const _this = this
      const file = e.target.files[0]
      const size = Math.floor(file.size / 1024 / 1024)
      if (size > 5) {
        return this.$message({
          message: '图片大小不能超过5M',
          type: 'warning'
        })
      }
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (e) => {
        _this.$api.OtherInStockUploadAccessory({
          FileExtName: '.' + file.type.split('/')[1],
          Base64String: e.target.result
        }).then(res => {
          _this.ruleForm1.Filepath = res.Message
        })
      }
    },
    // 新增地址/ 编辑
    submitAddress(formName) {
      console.log(this.ruleForm1)
      if (!this.ruleForm1.IDX) {
        this.ruleForm1.Province = CodeToText[this.ruleForm1.Province[0]]
        this.ruleForm1.City = CodeToText[this.ruleForm1.City[0]]
        this.ruleForm1.Area = CodeToText[this.ruleForm1.Area[0]]
      }
      if (this.ruleForm1.IDX) {
        this.ruleForm1.Province = CodeToText[this.ruleForm1.Province]
        this.ruleForm1.City = CodeToText[this.ruleForm1.City]
        this.ruleForm1.Area = CodeToText[this.ruleForm1.Area]
      }
      this.ruleForm1.CompanyID = this.from.BEDNRCompanyID ? this.from.BEDNRCompanyID : 0
      this.$refs[formName].validate((valid) => {
        if (!valid) return false
        this.ruleForm1.TakeSelf ? this.ruleForm1.TakeSelf = 1 : this.ruleForm1.TakeSelf = 0
        // 保存地址
        this.$api.SaveUserAddress(this.ruleForm1).then(res => {
          console.log(res)
          this.showSetAddress = false
          if (res.RetCode === '0') {
            if (this.ruleForm1.AddrType === 10) {
              this.QueryDeliveryAddressList()
            }
            if (this.ruleForm1.AddrType === 20) {
              this.QueryCollectTicketAddressList()
            }
            this.ruleForm1.CompanyID = ''
            return this.$message.success('保存成功')
          }
          this.ruleForm1.CompanyID = ''
          return this.$message.error('保存失败')
        })
      })
    }
  }
}
export default Index
